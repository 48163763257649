.custom-switch {
  position: relative;
  display: inline-block;
  padding: 0;

  .custom-control-label {
    padding-left: 40px;
    cursor: pointer;
    &:before {
      width: 36px;
      height: 18px;
      border-radius: 10px;
      top: 2px !important;
      left: 0px !important;
    }

    &:after {
      width: 8px;
      height: 8px;
      top: 7px !important;
      left: 5px !important;
      border-width: 2px;
    }
  }

  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &:after {
          // background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='4' fill='%236FB989' stroke='%236FB989' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          border: 2px solid $success;
          // background-color: $white;
          background-color: $success;
          left: 13px !important;
        }

        &:before {
          background: transparent;
          border-color: $success;
        }
      }
    }

    &:disabled,
    &[disabled] {
      ~ .custom-control-label {
        &:before {
          border: 1px solid $gray-400;
        }
      }
    }

    &:focus {
      ~ .custom-control-label {
        &:before {
          outline: 2px solid $white;
        }
      }
    }
  }
}
