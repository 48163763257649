.interview-agent {
  .bg-color {
    background-color: #f0edf7;
  }
  .b-bottom {
    border-bottom: 1px solid $gray-300;
  }
  #chatbot {
    font-size: $font-size-base;
    background-color: $gray-bg;
    height: calc(100vh - 135px);
    .chat-body {
      height: calc(100vh - 231px);
      .welcome-message {
        top: 40%;
      }
    }
    .chat-body-min {
      height: calc(100vh - 295px);
    }
    .chats-scroll {
      overflow: hidden;
      overflow-y: scroll;
      width: 100%;
    }

    .chat-footer {
      box-shadow: 0px -1px 11px 0px rgba(0, 0, 0, 0.05);
    }
    .user-message {
      background-color: #6569651a;
      color: #4a484f;
      border-radius: 4px;
      padding: 14px 16px;
      // text-align: right;
    }

    .bot-message {
      background-color: #f0edf7;
      color: #4a484f;
      border-radius: 4px;
      padding: 14px 16px;
      width: fit-content;
    }
    .candidate-message {
      background-color: #f0f8f7;
      color: #4a484f;
      border-radius: 4px;
      padding: 14px 16px;
      width: fit-content;
    }

    .chat {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      // margin: 10px;
    }

    .user-chat {
      flex-direction: row-reverse;
    }

    .bot-img {
      background-color: $primary;
      width: 39px;
      height: 39px;
      border-radius: 3px;
      padding: 13px;
      color: $white;
    }

    .candidate-img {
      background-color: $success;
      width: 39px;
      height: 39px;
      border-radius: 3px;
      padding: 13px;
      color: $white;
    }

    .user-name {
      display: block;
      font-weight: 400;
      color: $gray-600;
    }

    .bot-message-loading {
      width: 60px;
      height: 40px;
      position: relative;
      padding: 18px;
      background: #f0edf7;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
    }

    .typing-dot {
      width: 6px;
      height: 6px;
      background: #8d8c91;
      border-radius: 50%;
      opacity: 0;
      animation: loadingFade 1s infinite;
    }

    .typing-dot:nth-child(1) {
      animation-delay: 0s;
    }

    .typing-dot:nth-child(2) {
      animation-delay: 0.2s;
    }

    .typing-dot:nth-child(3) {
      animation-delay: 0.4s;
    }

    @keyframes loadingFade {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0.8;
      }
      100% {
        opacity: 0;
      }
    }
  }
  .increaseFontSize h3 {
    font-size: 2em !important;
  }
  .increaseFontSize h4 {
    font-size: 1.5em !important;
  }
  .increaseFontSize h6,
  .increaseFontSize span,
  .increaseFontSize p,
  .increaseFontSize ol {
    font-size: 1.4em !important;
  }
}
