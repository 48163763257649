.btn {
  padding: 5.8px 12px;
  border-radius: 4px;
  text-decoration: none;
  // text-transform: capitalize;
  min-width: 80px;

  &:focus {
    box-shadow: none !important;
    text-decoration: none;
  }

  [class^='ft-'] {
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
  }

  // size
  &-sm {
    padding: 4px 12px;
    min-width: auto;
  }

  &-lg {
    padding: 8px 16px;
    // text-transform: uppercase;
  }

  // color
  // primary
  &-primary {
    &:hover,
    &:focus {
      background-color: $primary-dark-1;
      border-color: $primary-dark-1;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $primary;
      border-color: $primary;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $primary;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $primary-dark-1;
      }

      &:disabled,
      &.disabled {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $gray-500 !important;
        cursor: default !important;
      }
    }
  }

  // secondary
  &-secondary {
    &:hover,
    &:focus {
      background-color: $secondary-dark-1;
      border-color: $secondary-dark-1;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $secondary;
      border-color: $secondary;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $secondary-dark-1;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $secondary-dark-2;
      }

      &:disabled,
      &.disabled {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $gray-500 !important;
        cursor: default !important;
      }
    }
  }

  // success
  &-success {
    color: $white;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $success;
      border-color: $success;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $secondary-dark-2;
      color: $success;
      text-decoration: underline;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $secondary-dark-1;
      }

      &:disabled,
      &.disabled {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $gray-500 !important;
        cursor: default !important;
      }
    }
  }

  // danger
  &-danger {
    &:hover,
    &:focus {
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $danger;
      border-color: $danger;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $danger;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $danger-light;
      }

      &:disabled,
      &.disabled {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $gray-500 !important;
        cursor: default !important;
      }
    }
  }

  // warning
  &-warning {
    color: $white;

    &:hover,
    &:focus {
      box-shadow: none;
      color: $white;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $warning;
      border-color: $warning;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $warning;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $warning-light;
      }

      &:disabled,
      &.disabled {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $gray-500 !important;
        cursor: default !important;
      }
    }
  }

  // info
  &-info {
    color: $white;

    &:hover,
    &:focus {
      box-shadow: none;
      color: $white;
    }

    &:not(:disabled):not(.disabled):active,
    .active {
      background-color: $info;
      border-color: $info;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $info;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $info-light;
      }

      &:disabled,
      &.disabled {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $gray-500 !important;
        cursor: default !important;
      }
    }
  }

  // light
  &-light {
    &:hover,
    &:focus {
      background-color: $gray-400;
      border-color: $gray-400;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &.active {
      background-color: $light;
      border-color: $light;
      box-shadow: none;
    }

    &-link {
      min-width: auto;
      color: $gray-500;

      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $gray-800;
      }

      &:disabled,
      &.disabled {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $gray-500 !important;
        cursor: default !important;
      }
    }
  }

  // dark

  // link
  &-link {
    min-width: auto;
    color: $dark;

    &:hover {
      text-decoration: none;
      color: $primary;
    }

    &:disabled,
    &.disabled {
      background-color: transparent !important;
      border-color: transparent !important;
      color: $gray-500 !important;
    }
  }

  //btn-group
  &-link-grp {
    min-width: auto;
    color: $dark;
    background-color: $gray-bg;

    &::after {
      content: '';
      height: 2px;
      background-color: $primary;
      display: block;
      visibility: hidden;
    }

    &:hover,
    &.active,
    &:focus {
      color: $primary;
      &::after {
        visibility: visible;
      }
    }
  }

  // disabled
  &-disabled,
  &:disabled,
  &.disabled {
    background-color: $gray-500;
    border-color: $gray-500;
    color: $white;
  }

  // stroke or outline

  &-outline {
    color: $gray-500;
    border-color: $gray-300;

    // primary
    &-primary {
      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // secondary
    &-secondary {
      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // success
    &-success {
      &:hover {
        color: $white;
      }

      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // danger
    &-danger {
      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // warning
    &-warning {
      &:hover {
        color: $white;
      }

      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // info
    &-info {
      &:hover {
        color: $white;
      }

      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // light
    &-light {
      color: $gray-500;
      border-color: $gray-500;

      &:disabled,
      &.disabled {
        background-color: transparent;
        border-color: $gray-500;
        color: $gray-500;
      }
    }

    // dark
    // link
  }

  &-icon {
    min-width: auto;

    &-primary {
      display: inline-block;
      border-radius: 4px;
      padding: 4px 6px;
      background-color: $bg-color;
      color: $primary;
    }
  }

  &-ft {
    display: inline-block;
    text-decoration: none;
    border: none;
    background-color: transparent;
    box-shadow: none !important;
    padding: 4px;

    //size
    &_sm {
      font-size: 16px;
    }

    &_lg {
      font-size: 24px;
    }

    //Color
    //Primary
    &_primary {
      color: $primary;

      &:hover,
      &:focus,
      &:active {
        color: darken($primary, 10%);
      }
    }

    &_secondary {
      color: $secondary;

      &:hover,
      &:focus,
      &:active {
        color: darken($secondary, 10%);
      }
    }

    &_success {
      color: $success;

      &:hover,
      &:focus,
      &:active {
        color: darken($success, 10%);
      }
    }

    &_danger {
      color: $danger;

      &:hover,
      &:focus,
      &:active {
        color: darken($danger, 10%);
      }
    }

    &_warning {
      color: $warning;

      &:hover,
      &:focus,
      &:active {
        color: darken($danger, 10%);
        color: $warning-light;
      }
    }
  }

  &-ft {
    display: inline-block;
    vertical-align: middle;

    &-round {
      border-radius: 100px;
      background-color: #f0f8f7;
      cursor: pointer;
      color: $green;
      padding: 4px 6px;
      line-height: 1;

      &:hover {
        background-color: $secondary-light-2;
        color: $success;
      }
    }
  }

  &-circle {
    border-radius: 50%;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    min-width: auto;
    [class^='ft-'] {
      margin-top: 0;
      font-weight: 800;
    }
    &_sm {
      @extend .btn-circle;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 12px;
    }
  }

  &-block {
    display: flex;
    align-items: center;
    border: 1px solid $bg-color;
    padding: 20px 20px;
    cursor: pointer;
    border-radius: 4px;
    color: $black;

    &:hover {
      background-color: $bg-color;
      text-decoration: none;
    }
  }

  &-setting {
    @extend .btn;
    @extend .btn-icon;
    padding: 0.25rem 0.5rem;
    &:hover {
      background-color: $bg-color;
      color: $primary;
    }
  }
}

.ft {
  &-round {
    border-radius: 100px;
    cursor: pointer;
    padding: 5px 5px;
    color: $white;
  }

  // link
  &-link {
    min-width: auto;
    color: $dark;

    &:hover {
      text-decoration: none;
      color: $primary;
    }

    &:disabled,
    &.disabled {
      background-color: transparent !important;
      border-color: transparent !important;
      color: $gray-500 !important;
    }
  }
  &-blue {
    background-color: $blue;
  }
}

.help {
  min-width: 1px;
  border-radius: 50%;
}

.uploader {
  cursor: pointer;
  margin: 0;
  font-weight: 400;
  input {
    opacity: 0;
  }
}

.sort-arrow {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  gap: 0.125rem;
  font-size: 0.5rem;
  i,
  svg {
    cursor: pointer;
    &:hover {
      color: #6d49b1;
    }

    &.disable {
      color: $gray-900;
      pointer-events: none;
    }
  }
  // margin: 1px 0 0 auto;
  // // color: $gray-700;
  // .up-arrow,
  // .down-arrow {
  //   width: 0;
  //   height: 0;
  //   border: solid 4px transparent;
  //   background: transparent;
  //   cursor: pointer;
  // }
  // .up-arrow {
  //   border-bottom: solid 5px $gray-500;
  //   border-top-width: 0;
  // }

  // .down-arrow {
  //   border-top: solid 5px $gray-500;
  //   border-bottom-width: 0;
  //   margin-top: 2px;
  // }
}
