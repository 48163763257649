.editor {
  &-container {
    border: 1px solid $border-color;
    border-radius: 0.375rem;
    background: white;
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1.5rem;
    }
    h5 {
      font-size: 1.25rem;
    }
    h6 {
      font-size: 1rem;
    }
    p {
      font-size: 0.875rem;
    }

    input {
      &[type='color'],
      &[type='file'] {
        opacity: 0;
        width: 0;
        position: absolute;
      }
    }
  }

  &-toolbar {
    padding: 0.5rem;
    border-bottom: 1px solid $gray-300;
    background-color: $gray-100;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    &.readOnly {
      button,
      select {
        pointer-events: none;
      }
    }

    .btn-group {
      &:not(:last-child) {
        border-right: 1px solid $border-color;
        .btn {
          &:last-child {
            margin-right: 0.5rem;
          }
        }
      }
      .btn {
        padding: 0.125rem 0.65rem;
        font-size: 1rem !important;
        border-color: transparent;

        &.is-active {
          background-color: $gray-300;
          border-color: $border-color;
        }

        &:hover {
          background-color: $gray-200;
        }
      }
    }
  }
}

.ProseMirror {
  padding: 0.5rem;
  min-height: 150px;
  outline: none;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 400;
  }

  img {
    display: block;
  }

  &.readOnly {
    background-color: #f1f1f1;
    color: #85858d;
    pointer-events: none;
  }

  a {
    color: $primary;
    cursor: pointer;
  }
  ul,
  ol {
    margin: 0;
    padding: 0 1rem;
    list-style-position: inside;

    li {
      font-weight: 400;
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      div {
        display: inline;
        font-size: inherit;
      }
    }
  }
  ul {
    list-style-type: disc;
  }
  ol {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-left: 0.5rem;
    }
  }
  a {
    color: $primary;
    cursor: pointer;
  }
  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    thead {
      background-color: #f8f9fa;
      color: #495057;

      th {
        padding: 0.5rem;
        vertical-align: bottom;
        border: 1px solid #dee2e6;
      }
    }

    tbody {
      td {
        padding: 0.5rem;
        vertical-align: top;
        border: 1px solid #dee2e6;
      }
    }
  }

  .image {
    &-controller {
      position: absolute;
      top: 0%;
      left: 50%;
      z-index: 999;
      background-color: white;
      border-radius: 4px;
      border: 1px solid $border-color;
      cursor: pointer;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
    }
  }
}

.tiptap {
  &-menu {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 0.25rem;
    display: flex;
    gap: 0.25rem;
    padding: 0.5rem;
    button {
      padding: 0.125rem 0.65rem;
      font-size: 1rem !important;
      border-color: transparent;

      &.is-active {
        background-color: $gray-300;
        border-color: $border-color;
      }

      &:disabled {
        background-color: $gray-200;
        color: white;
        border: none;
      }

      &:hover {
        background-color: $gray-200;
      }
    }
  }
}
